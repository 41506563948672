<template>
    <div>
        <!-- title -->
        <div class="container-fluid bg-slider-contact-form px-0 position-relative">
            <div class="row position-relative m-0">
                <div class="col-12 py-5 px-0">
                    <div class="container py-5">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <h1 class="d-lg-block d-none text-white mp-rbk mp-font-weight-medium font-size-faq-header-title">Impressum</h1>
                                <h1 class="d-lg-none d-block text-white mp-rbk mp-font-weight-medium font-size-faq-header-title-mobile">Impressum</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /title -->
        <!-- content -->
        <div class="container-fluid bg-lightblue py-5">
            <div class="row">
                <div class="col-10 offset-1 col-md-4 offset-md-2 offset-lg-3 col-lg-3">
                    <p>
                        <b>Firmenwortlaut</b><br>
                        Lisa Graf e.U.
                    </p>
                    <p>
                        <b>Anschrift und Kontakt</b><br>
                        Rubin-Bittmann Prom. 3/3/14<br>
                        1030 Wien<br>
                        +43664 287 92 38<br>
                        office@mergeplate.com
                    </p>
                    <p>
                        <b>Unternehmensgegenstand</b><br>
                        Betrieb eines Onlinedienstes (Vermittlungsplattform)
                    </p>

                </div>
                <div class="col-10 offset-1 col-md-4 col-lg-3 offset-md-0 offset-lg-0">
                    <p>
                        <b>UID-Nummer</b><br>
                        ATU77509858
                    </p>
                    <p>
                        <b>Firmenbuchnummer</b><br>
                        FN561798
                    </p>
                    <p>
                        <b>Firmenbuchgericht</b><br>
                        Handelsgericht Wien
                    </p>
                    <p>
                        <b>Firmensitz</b><br>
                        Wien, Österreich
                    </p>
                </div>
            </div>
        </div>
        <!-- /content -->
    </div>
</template>


<script>
    import {mixinBasics} from "../../mixins/basics";

    export default {
        name: "Imprint",

        metaInfo() {
            return {
                title: 'Impressum',
                meta: [
                    { name: 'Impressum | mergeplate.com' },
                    { title: 'Impressum | mergeplate.com' }
                ].concat(this.buildOpenGraphMetaTags(
                    `Impressum | mergeplate.com`,
                    `Impressum - mergeplate.com - finde deinen Experten auf der Plattform für mentale und physische Gesundheit`,
                    this.$route.path
                ))
            };
        },

        mixins: [
            mixinBasics
        ],

        mounted() {
            window.scrollTo(0,0);
        }
    }
</script>


<style scoped>

</style>
